@if (title !== '') {
  <h2 mat-dialog-title>{{title}}</h2>
}
@if (text !== '') {
  <mat-dialog-content class="mat-typography">
    {{text}}
  </mat-dialog-content>
}
<mat-dialog-actions align="end">
  @if (cancel !== '') {
    <button mat-button [mat-dialog-close]="false">{{cancel}}</button>
  }
  @if (confirm !== '') {
    <button mat-button [mat-dialog-close]="true">{{confirm}}</button>
  }
</mat-dialog-actions>
