import {Component, Inject} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from '@angular/material/dialog';

export interface DialogConfirmData {
  text: string;
  title?: string;
  cancel?: string;
  confirm?: string;
}

@Component({
  selector: 'app-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogContent,
    MatDialogClose,
    MatButton,
    MatDialogTitle,
  ],
})
export class DialogConfirmComponent {
  public readonly text: string;
  public readonly title: string;
  public readonly cancel: string;
  public readonly confirm: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData,
  ) {
    this.text = data.text;
    this.title = data.title ?? '';
    this.cancel = data.cancel ?? 'Abbrechen';
    this.confirm = data.confirm ?? 'OK';
  }
}
